import { useSession } from "next-auth/react";
import Head from "next/head";
import Link from "next/link";
import { useRouter } from "next/router";
import { User } from "phosphor-react";
import { useEffect, useState } from "react";
import { ClientOnly } from "../components/ClientOnly";
import { InvertedMediumButtonWithIcon } from "../components/shared";
import { serifPageTitle } from "../components/sharedStyles";

export default function Web() {
  return (
    <>
      <Head>
        <title>Spreadsheet.Page</title>
      </Head>
      <div className="h-screen content-center justify-center grid gap-8 text-center px-4">
        <h1 className={`${serifPageTitle}`}>Spreadsheet.Page</h1>
        <h2 className="font-serif text-2xl md:text-3xl tracking-tight text-neutral-500">
          &#8220;kinda like a spreadsheet, kinda like a page&#8221;
        </h2>
        <ClientOnly>
          <SignInLinkOrNavigateToProjects />
        </ClientOnly>
      </div>
    </>
  );
}

/**
 * Return loading if status is loading
 * If status is authenticated, navigate to /projects
 * If status is not authenticated, return a sign in link
 */
function SignInLinkOrNavigateToProjects() {
  const { status } = useSession();
  const router = useRouter();
  const [isNavigating, setIsNavigating] = useState(false);
  const isLoading = status === "loading" || isNavigating;
  useEffect(() => {
    if (status === "authenticated") {
      setIsNavigating(true);
      router.push("/projects");
    }
  }, [status, router]);
  return (
    <p className="mt-1 justify-self-center">
      <InvertedMediumButtonWithIcon
        as={Link}
        href="/sign-in"
        icon={<User className="mt-[-4px]" size={24} />}
        isLoading={isLoading}
      >
        Sign In
      </InvertedMediumButtonWithIcon>
    </p>
  );
}

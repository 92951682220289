import { forwardRef } from "react";
import { CircleNotch, Copy, IconProps, WarningCircle } from "phosphor-react";

export const IconButton = forwardRef<
  HTMLButtonElement,
  React.ComponentPropsWithoutRef<"button">
>(function IconButton({ children, className = "", ...props }, ref) {
  return (
    <button
      ref={ref}
      className={`flex items-center justify-center w-8 h-8 transition-colors duration-200 opacity-30 hover:opacity-80 active:opacity-100 ${className}}`}
      {...props}
    >
      {children}
    </button>
  );
});

const boldIconButtonColors = {
  purple: "text-purple-400 hover:text-purple-600 active:text-purple-700",
  red: "text-red-400 hover:text-red-600 active:text-red-700",
  green: "text-green-400 hover:text-green-600 active:text-green-700",
  orange: "text-orange-400 hover:text-orange-600 active:text-orange-700",
  neutral: "text-neutral-400 hover:text-neutral-600 active:text-neutral-700",
} as const;
export const BoldIconButton = forwardRef<
  HTMLButtonElement,
  React.ComponentPropsWithoutRef<"button"> & {
    color?: keyof typeof boldIconButtonColors;
  }
>(function BoldIconButton(
  { children, color = "neutral", className = "", ...props },
  ref
) {
  return (
    <button
      ref={ref}
      className={`flex hover:rotate-[10deg] hover:scale-[1.05] transition-transform items-center justify-center w-8 h-8 ${boldIconButtonColors[color]} ${className}}`}
      {...props}
    >
      {children}
    </button>
  );
});

export function WarningChip({
  children,
  className = "",
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <div
      className={`flex items-center justify-start bg-red-100 text-red-600 font-medium rounded-full px-2 pr-6 py-1 ${className}`}
    >
      <WarningCircle size={24} weight="fill" className="mr-2 w-12 h-12" />
      <span className="mt-[2px] text-base md:text-lg md:mt-[3px]">
        {children}
      </span>
    </div>
  );
}

const colors = {
  purple:
    "bg-purple-100 text-purple-600 hover:bg-purple-200 active:bg-purple-300",
  red: "bg-red-100 text-red-600 hover:bg-red-200 active:bg-red-300",
  green: "bg-green-100 text-green-600 hover:bg-green-200 active:bg-green-300",
  orange:
    "bg-orange-100 text-orange-600 hover:bg-orange-200 active:bg-orange-300",
  neutral:
    "bg-neutral-200 text-neutral-600 hover:bg-neutral-300 active:bg-neutral-400",
};
export const SmallSquareIconButton = forwardRef<
  HTMLButtonElement,
  React.ComponentPropsWithoutRef<"button"> & {
    color: keyof typeof colors;
  }
>(function SmallSquareIconButton(
  { children, className = "", color = "neutral", ...props },
  ref
) {
  return (
    <button
      ref={ref}
      className={`w-6 h-6 grid content-center justify-center rounded ${className} ${colors[color]}`}
      {...props}
    >
      {children}
    </button>
  );
});

const largeSerifButtonColors = {
  purple: "to-purple-300 hover:to-purple-400",
  red: "to-red-300 hover:to-red-400",
  green: "to-green-300 hover:to-green-400",
  orange: "to-orange-300 hover:to-orange-400",
  neutral: "to-neutral-300 hover:to-neutral-400",
};
export const LargeSerifButton = forwardRef(function LargeSerifButton<
  T extends React.ElementType = "button"
>(
  {
    as,
    children,
    className = "",
    color = "neutral",
    ...props
  }: React.ComponentPropsWithoutRef<T> & {
    as?: T;
    color?: keyof typeof largeSerifButtonColors;
  },
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const Component = as ?? "button";
  return (
    <Component
      ref={ref}
      className={`text-3xl text-neutral-800 text-center font-serif tracking-tighter px-8 pt-7 pb-5 block rounded-lg bg-gradient-to-br from-neutral-200 ${largeSerifButtonColors[color]} hover:text-foreground ${className}`}
      {...props}
    >
      {children}
    </Component>
  );
});

const invertedButtonColors = {
  neutral: "bg-neutral-700 hover:bg-neutral-800 hover:ring-neutral-400",
  purple: "bg-purple-700 hover:bg-purple-800 hover:ring-purple-400",
  red: "bg-red-700 hover:bg-red-800 hover:ring-red-400",
  green: "bg-green-700 hover:bg-green-800 hover:ring-green-400",
  orange: "bg-orange-700 hover:bg-orange-800 hover:ring-orange-400",
};

type InvertedButtonProps<T extends React.ElementType> = {
  as?: T;
  icon: React.ReactNode;
  color?: keyof typeof invertedButtonColors;
  isLoading?: boolean;
};

export const InvertedMediumButtonWithIcon = forwardRef(
  function PolymorphicInvertedMediumButtonWithIcon<
    T extends React.ElementType = "button"
  >(
    {
      as,
      children,
      className = "",
      icon,
      color = "neutral",
      isLoading = false,
      ...props
    }: InvertedButtonProps<T> & Omit<React.ComponentPropsWithRef<T>, "color">,
    ref: React.ForwardedRef<HTMLButtonElement>
  ) {
    const Component = as ?? "button";
    return (
      <Component
        ref={ref}
        className={`${invertedButtonColors[color]} flex relative rounded text-background p-3 pb-2 pr-5 text-lg ring-offset-0 transition-all hover:ring-2 hover:ring-offset-2 ${className}`}
        {...props}
      >
        <div
          className={`flex w-full items-center gap-2 ${
            isLoading ? "opacity-0" : ""
          } transition-opacity`}
        >
          {icon}
          <span className="ml-1 text-center grow text-base md:text-lg">
            {children}
          </span>
        </div>
        <div
          className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 transition-opacity ${
            isLoading ? "" : "opacity-0"
          }`}
        >
          <CircleNotch className="animate-spin origin-center" size={30} />
        </div>
      </Component>
    );
  }
);

export function LargeYellowNoticeBlock({
  children,
  className = "",
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <div
      className={`bg-orange-100 border-orange-200 border border-b-2 p-4 pb-3 rounded-lg flex items-center ${className}`}
    >
      <WarningCircle
        size={24}
        weight="fill"
        className="mr-2 mt-[-4px] text-orange-500"
      />
      <p className="text-lg text-orange-900">{children}</p>
    </div>
  );
}

export function CopyCodeStringButton({
  code,
  className = "",
}: {
  code: string;
  className?: string;
}) {
  return (
    <div
      data-copy-box
      className={`bg-neutral-700 hover:bg-neutral-800 p-3 rounded-lg flex items-center justify-between justify-self-start gap-4 data-[copied=true]:ring-4 ring-offset-2 ring-green-300 transition-all ${className}`}
    >
      <input
        type="text"
        readOnly
        className="w-80 font-mono text-sm text-neutral-50 bg-transparent focus:outline-none"
        value={code}
      />
      <IconButton
        className="text-neutral-50 hover:text-neutral-100 active:text-neutral-200"
        onClick={(e) => {
          navigator.clipboard.writeText(code);
          // data-copied="true" on the data-copy-box ancestor
          const closest = e.currentTarget.closest("[data-copy-box]");
          if (closest) {
            closest.setAttribute("data-copied", "true");
            setTimeout(() => {
              closest.removeAttribute("data-copied");
            }, 2000);
          }
        }}
      >
        <Copy size={24} />
      </IconButton>
    </div>
  );
}

const beveledOuterColors = {
  orange:
    "border-orange-300 hover:bg-orange-300 hover:border-orange-400 data-[state=open]:bg-orange-300 data-[state=open]:border-orange-400",
  purple:
    "border-purple-300 hover:bg-purple-300 hover:border-purple-400 data-[state=open]:bg-purple-300 data-[state=open]:border-purple-400",
  red: "border-red-300 hover:bg-red-300 hover:border-red-400 data-[state=open]:bg-red-300 data-[state=open]:border-red-400",
  green:
    "border-green-300 hover:bg-green-300 hover:border-green-400 data-[state=open]:bg-green-300 data-[state=open]:border-green-400",
  neutral:
    "border-neutral-300 hover:bg-neutral-300 hover:border-neutral-400 data-[state=open]:bg-neutral-300 data-[state=open]:border-neutral-400",
};
const beveledInnerColors = {
  orange: "text-orange-600",
  purple: "text-purple-600",
  red: "text-red-600",
  green: "text-green-600",
  neutral: "text-neutral-600",
};
export const SmallSquareBeveledIconButton = forwardRef(
  function SmallSquareBeveledIconButton<T extends React.ElementType = "button">(
    {
      as,
      children,
      className = "",
      color = "neutral",
      icon,
      ...props
    }: React.ComponentPropsWithoutRef<T> & {
      as?: T;
      color?: keyof typeof beveledOuterColors;
      icon: React.ForwardRefExoticComponent<
        IconProps & React.RefAttributes<SVGSVGElement>
      >;
    },
    ref: React.ForwardedRef<HTMLButtonElement>
  ) {
    const Component = as ?? "button";
    const Icon = icon;
    return (
      <Component
        ref={ref}
        className={`rounded border w-8 h-8 min-w-[2rem] border-b-2 grid content-center justify-center active:border-b ${beveledOuterColors[color]} ${className}`}
        {...props}
      >
        <Icon size={18} className={`${beveledInnerColors[color]}`} />
      </Component>
    );
  }
);

const chipColors = {
  orange: "bg-orange-100 text-orange-600",
  purple: "bg-purple-100 text-purple-600",
  red: "bg-red-100 text-red-600",
  green: "bg-green-100 text-green-600",
  neutral: "bg-neutral-100 text-neutral-600",
};

export function Chip({
  children,
  className = "",
  color = "neutral",
}: {
  children: React.ReactNode;
  className?: string;
  color?: keyof typeof chipColors;
}) {
  return (
    <div
      className={`rounded-full font-sans tracking-normal px-4 pt-[6px] pb-[1px] text-xl ${className} ${chipColors[color]}`}
    >
      {children}
    </div>
  );
}

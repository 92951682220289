import { ColumnType } from "docrunner";

export const sharedPopoverContentStyles =
  "rounded-lg bg-background shadow-lg will-change-[transform,opacity] data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade";

export const iframeScriptEditorSharedHeight = "200px";

/* Serif Styles */
export const serifPageTitle =
  "font-serif text-4xl md:text-5xl tracking-tighter";
export const superLgSerifTitle = "font-serif text-4xl md:text-5xl";

export const collectionTitleClasses =
  "font-serif text-2xl md:text-3xl tracking-tighter text-neutral-500";

/* Sans Styles */
export const sansLgDescription = "text-lg md:text-xl text-neutral-600";

export const sansH2 = "text-2xl md:text-3xl text-foreground";

/* Collection Column Header */
const sharedHeaderCellStyles = `px-2 py-1`;
const sharedHeaderCellColor: Record<ColumnType, string> = {
  prompt: "bg-purple-200 border-purple-300",
  script: "bg-orange-200 border-orange-300",
  text: "bg-neutral-200 border-neutral-300",
};

export function completeHeaderCellStyles(columnType: ColumnType) {
  return `${sharedHeaderCellStyles} ${sharedHeaderCellColor[columnType]}`;
}
